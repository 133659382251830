
import React, { useRef, useState, useEffect, useContext } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { loggedUser,handleResetPassword,verifyResetLink} from  "../components/FirebaseAuth"
import { Link } from "gatsby"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import ThemeContext from "../utils/theme"

export default function ResetPassword() 
{
  const passwordRef = useRef()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState("")
  const [validURL, setValidURL] = useState(false)
  const { dark } = useContext(ThemeContext)

  useEffect( () => 
  {
    async function run()
    {
      let searchParams
      if (typeof window !== 'undefined') 
      { searchParams = new URLSearchParams(window.location.search); }

      let actionCode 
      let mode 
      if(searchParams)
      {
        actionCode = searchParams.get('oobCode')
        mode = searchParams.get('mode')
      }
      //console.log(mode,actionCode)

      if(mode == "resetPassword")
      {
        let ret = await verifyResetLink(actionCode)
        //console.log("ret code is",ret)
        if( ret )
        { 
          if( ret["error"] == undefined)
          {
            setValidURL(true)
            setEmail( String(ret) )
            setError("")
          }
          else if (ret["error"] != undefined)
          {
            setError(String(ret["error"]))
          }
        }
        else
        {
          setError("Invalid URL. That URL may have expired.")
        }
      }
      else
      {
        setError("Invalid URL. Please refresh with a valid URL.")
      }
    }
    run()  
  },[])

  async function callReset()
  {
    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let actionCode
    if(searchParams)
    { actionCode = searchParams.get('oobCode') }
    let ret = await handleResetPassword(actionCode,email,passwordRef.current.value)

    if(ret)
    {
      if( ret["error"] == undefined)
      {
        setMessage("You have successfully reset your password and have been logged in!")
      }
      else if (ret["error"] != undefined)
      {
        setError(String(ret["error"]))
      }
    }
    else
    {
      setError("Password reset failed.")
    }
  }

  function handleSubmit(e) 
  {
    e.preventDefault()
    try 
    {
      setMessage("")
      setError("")
      setLoading(true)
      callReset()
    } 
    catch 
    {
      setError("Failed to reset password")
    }
    setLoading(false)
  }

  return (
    <>
      <PageLayout>
        <SEO title="Forget Password" />
        <Container className="d-flex align-items-center justify-content-center" >
          <div className="w-100 text-color" style={{ maxWidth: "400px" }}>
            <Card style={{ borderColor:`${dark ? '#333' : '#ddd'}`,borderWidth:"3px" }}>
              <Card.Body>

                <h2 className="text-center mb-4">Password Reset</h2>
                {error && <Alert style={{fontWeight:"900",backgroundColor:"#d9534f",color:"white",borderColor:"#d9534f"}}>{error}</Alert>}
                {message && <Alert style={{fontWeight:"900",backgroundColor:"#5cb85c",color:"white",borderColor:"#5cb85c"}}>{message}</Alert>}

                {validURL &&
                  <Form onSubmit={handleSubmit}>
                    {email && <div> Welcome {email} <br/><br/> </div>}
                    <Form.Group id="password">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password" ref={passwordRef} required />
                    </Form.Group>
                    <Button disabled={loading} type="submit">
                      Reset Password
                    </Button>
                  </Form>
                }
                <div className="w-100 text-center mt-3">
                  <Link to="/Login">Login</Link>
                </div>
                
              </Card.Body>
            </Card>

            <div className="w-100 text-center mt-2">
              Need an account? <Link to="/Signup">Sign Up</Link>
            </div>
          </div>
        </Container>
      </PageLayout>
    </>
  )
}